<template>
  <div>
    <Toasts ref="Toasts"></Toasts>
    <section v-show="seeData" class="tables">
      <div class="row">
        <div class="col">
          <div class="card border-0">
            <slot name="header"></slot>
            <div class="mx-md-3">
              <slot name="filter"></slot>
              <b-table
                class="text-center"
                :items="items"
                id="table-list"
                responsive
                :busy="loader"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
              >
                <template #table-busy>
                  <div>
                    <b-skeleton-table class="mt-2" :columns="4" :rows="3" />
                  </div>
                </template>
                <template #cell(amount)="data">
                  <b :class="formatTags(data.item.amount)['tagVariant']">{{
                    data.item.original_value ? formatAmount(data.item.original_value) : formatAmount(data.item.amount)
                  }}</b>
                </template>
                <template #cell(antecipated_amount)="data">
                  <b :class="formatTags(data.item.amount)['tagVariant']">{{
                    formatAmount(data.item.amount) 
                  }}</b>
                </template>
                <template #cell(description)="data">
                  <b :class="formatTags(data.item.amount)['tagVariant']">{{
                    data.item.description
                  }}</b>
                </template>
                <template #cell(created)="data">
                  <b :class="formatTags(data.item.amount)['tagVariant']">{{
                    formatDate(data.item.created)
                  }}</b>
                </template>
                <template #cell(paid_at)="data">
                  <b :class="formatTags(data.item.amount)['tagVariant']">{{
                    data.item.paid_at ? formatDate(data.item.paid_at) : formatDate(data.item.created)
                  }}</b>
                </template>
                <template #cell(antecipation_requested_at)="data">
                  <b :class="formatTags(data.item.amount)['tagVariant']">{{
                    data.item.antecipation_requested_at ? formatDate(data.item.antecipation_requested_at) : "Não Solicitada"
                  }}</b>
                </template>
                <template #cell(antecipation_analised_at)="data">
                  <b :class="formatTags(data.item.amount)['tagVariant']">{{
                    data.item.antecipation_analised_at ? formatDate(data.item.antecipation_analised_at) : "Não Analisada"
                  }}</b>
                </template>
                <template v-slot:cell(actions)="data">
                  <router-link
                    class="btn btn-warning btn-sm text-white mr-2 btn-fw"
                    :to="{
                      name: 'bank_statement_info',
                      params: {
                        id: data.item.id,
                        account_type: 'user',
                        workspace_id: workspaceId,
                      },
                    }"
                    v-b-tooltip.html.left
                    title="Abrir"
                  >
                    <i class="fa fa-search"></i>
                  </router-link>
                </template>
              </b-table>
            </div>
            <div v-if="rows > 20" class="row">
              <div class="col">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  align="center"
                  :per-page="perPage"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="!seeData && !loader" class="tables">
      <div class="row">
        <div class="col">
          <div class="card border-0" style="text-align: center">
            <i class="mdi mdi-eye-off text-muted" style="font-size: 50px"></i>
            <h3 class="text-muted">Extrato ocultado</h3>
          </div>
        </div>
      </div>
    </section>
    <section v-if="!rows && seeData && !loader" class="tables">
      <div class="row">
        <div class="col">
          <div class="card border-0 my-5" style="text-align: center">
            <i class="mdi mdi-note-outline text-muted" style="font-size: 50px"
              >!</i
            >
            <h3 class="text-muted">Não há nenhum registro!</h3>
            <h4 class="text-muted">Cheque os filtros ou faça uma transação.</h4>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PlgBankServicesApi from "../../apis/PlgBankServicesApi.vue";
import Toasts from "../../components/Toasts.vue";
import moment from "moment";

export default {
  name: "plg-bank-statement",
  props: {
    workspaceId: {
      type: String,
      default: null,
    },
    seeData: {
      type: Boolean,
      default: true,
    },
    defaultWorkspace: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Toasts,
  },
  mixins: [PlgBankServicesApi],
  computed: {
    totalOuts() {
      if (this.outsArray.length) {
        return (
          this.outsArray.reduce((previousValue, currentValue) => {
            return parseFloat(previousValue) + parseFloat(currentValue);
          }) / 100
        );
      } else {
        return 0;
      }
    },
    totalIns() {
      if (this.insArray.length) {
        return (
          this.insArray.reduce((previousValue, currentValue) => {
            return parseFloat(previousValue) + parseFloat(currentValue);
          }) / 100
        );
      } else {
        return 0;
      }
    },
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      sortBy: "created",
      sortDesc: true,
      perPage: 20,
      currentPage: 1,
      today: today,
      outsArray: [],
      insArray: [],
      loader: false,
      fields: [],
      items: [],
      rows: 0,
    };
  },
  watch: {
    workspaceId() {
      this.refreshTransactions();
    },
  },
  methods: {
    separateValues(amount) {
      if (amount < 0) {
        let positiveAmount = amount * -1;
        this.outsArray.push(positiveAmount);
      } else {
        this.insArray.push(amount);
      }
    },
    formatTags(amount) {
      let tagVariant, formatedTag;
      if (amount < 0) {
        tagVariant = "text-dark";
        formatedTag = "Saíram";
      } else {
        tagVariant = "text-success";
        formatedTag = "Você recebeu";
      }
      return { formatedTag, tagVariant };
    },
    formatDate(date) {
      let newDate = moment(date, "YYYY-MM-DDTHH:mm:ssZ").format("DD/MM/YYYY");
      return newDate;
    },
    formatAmount(amount) {
      let newAmount = amount / 100;
      let sign = "+";
      if (newAmount < 0) {
        newAmount = newAmount * -1;
        sign = "-";
      }
      return `${sign} R$ ${newAmount.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
      })}`;
    },
    async refreshTransactions() {
      this.loader = true;
      const payload = {
        request_payload: [],
      };
      const response = this.defaultWorkspace
        ? await this.seeDefaultWorkspaceStatement(payload)
        : await this.seeAccountStatement(payload, this.workspaceId);
      if (response.data.status != "success") {
        this.$refs.Toasts.showToast(
          response.data.status,
          response.data.message
        );
      }
      this.rows = response.data.transactions_total_count;
      this.items = response.data.transactions;
      this.fields = [
        { key: "amount", label: "Valor Original" },
        { key: "antecipated_amount", label: "Valor Pago" },
        { key: "description", label: "Motivo" },
        { key: "paid_at", label: "Pago Em" },
        { key: "antecipation_requested_at", label: "Antecipação Solicitada Em" },
        { key: "antecipation_analised_at", label: "Antecipação Analisada Em" },
        { key: "created", label: "Data" },
        { key: "actions", label: "Ações" },
      ];
      if (!this.defaultWorkspace) {
        this.items.forEach((item) => {
          this.separateValues(item.amount);
        });
        const emit = {
          totalOuts: this.totalOuts,
          totalIns: this.totalIns,
        };
        this.$emit("putTotals", emit);
      }
      this.loader = false;
    },
  },
  mounted() {
    this.refreshTransactions();
  },
};
</script>
